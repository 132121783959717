<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch" nobody>
              <div class="iq-card-body">
                <h6>Citas Totales</h6>
                <h3>
                  <b>{{ dashboardData.totalAppointments }}</b>
                </h3>
              </div>
              <ApexChart element="wave-chart-1" :chartOption="chart1" :isLive="true" />
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch" nobody>
              <div class="iq-card-body">
                <h6>Pacientes</h6>
                <h3>
                  <b>{{ dashboardData.totalPatients }}</b>
                </h3>
              </div>
              <ApexChart element="wave-chart-2" :chartOption="chart2" :isLive="true" />
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch" nobody>
              <div class="iq-card-body">
                <h6>Próximas Citas</h6>
                <h3>
                  <b>{{ dashboardData.totalCurrentAppointments }}</b>
                </h3>
              </div>
              <ApexChart element="wave-chart-3" :chartOption="chart3" :isLive="true" />
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch" nobody>
              <div class="iq-card-body">
                <h6>Tratamientos</h6>
                <h3>
                  <b>{{ dashboardData.totalTreatments }}</b>
                </h3>
              </div>
              <ApexChart element="wave-chart-4" :chartOption="chart4" :isLive="true" />
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">Citas Próximas</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown
              size="lg p-0"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <span
                  class="dropdown-toggle p-0"
                  id="dropdownMenuButton5"
                  data-toggle="dropdown"
                >
                  <i class="ri-more-fill m-0 text-primary"></i>
                </span>
              </template>
              <b-dropdown-item href="#"
                ><i class="ri-eye-fill mr-2"></i>View</b-dropdown-item
              >
              <b-dropdown-item href="#"
                ><i class="ri-delete-bin-6-fill mr-2"></i>Delete</b-dropdown-item
              >
              <b-dropdown-item href="#"
                ><i class="ri-pencil-fill mr-2"></i>Edit</b-dropdown-item
              >
              <b-dropdown-item href="#"
                ><i class="ri-printer-fill mr-2"></i>Print</b-dropdown-item
              >
              <b-dropdown-item href="#"
                ><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:body>
            <div class="table-responsive">
              <table class="table mb-0 table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Paciente</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Hora</th>
                    <th scope="col">Estado</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(appointment, index) in dashboardData.currentAppointments"
                  :key="appointment.id"
                >
                  <tr>
                    <td>{{ ++index }}</td>
                    <td>{{ appointment.patient.user.second_last_name }} {{ appointment.patient.user.first_last_name }}, {{ appointment.patient.user.name }}</td>
                    <td>{{ formatDate(appointment.date) }}</td>
                    <td>{{ appointment.start_time }} - {{ appointment.final_time }}</td>
                    <td>
                      <b-badge v-if="appointment.status === 'AGENDADA'" variant="primary">
                        {{ appointment.status }}
                      </b-badge>
                      <b-badge
                        v-else-if="appointment.status === 'REAGENDADA'"
                        variant="secondary"
                      >
                        {{ appointment.status }}
                      </b-badge>
                      <b-badge
                        v-else-if="appointment.status === 'CANCELADO'"
                        variant="danger"
                      >
                        {{ appointment.status }}
                      </b-badge>
                      <b-badge v-else variant="success">{{ appointment.status }}</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import IqCard from '../../../components/xray/cards/iq-card'
import { xray } from '../../../config/pluginInit'
import api from '../../../services/api'
import { Toast } from '../../../mixins/toast'

const body = document.getElementsByTagName('body')

export default {
  name: 'DashboardDoctor',
  components: { IqCard },
  data () {
    return {
      dashboardData: [],
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      appointments: [
        {
          patient: 'Petey Cruiser',
          doctor: 'Dr.Monty Carlo',
          date: '20/02/2020',
          timing: '8:00 AM',
          contact: '+1-202-555-0146'
        },
        {
          patient: 'Anna Sthesia',
          doctor: 'Dr.Pete Sariya',
          date: '25/02/2020',
          timing: '8:30 AM',
          contact: '+1-202-555-0164'
        },
        {
          patient: 'Paul Molive',
          doctor: 'Dr.Brock Lee',
          date: '20/02/2020',
          timing: '9:45 AM',
          contact: '+1-202-555-0153'
        },
        {
          patient: 'Anna Mull',
          doctor: 'Dr.Barb Ackue',
          date: '27/02/2020',
          timing: '11:30 AM',
          contact: '+1-202-555-0154'
        },
        {
          patient: 'Paige Turner',
          doctor: 'Dr.Walter Melon',
          date: '28/02/2020',
          timing: '3:30 AM',
          contact: '+1-202-555-0101'
        },
        {
          patient: 'Don Stairs',
          doctor: 'Dr.Arty Ficial',
          date: '28/02/2020',
          timing: '4:30 PM',
          contact: '+1-202-555-0176'
        },
        {
          patient: 'Pat Agonia',
          doctor: 'Dr.Barb Ackue',
          date: '29/02/2020',
          timing: '5:00 AM',
          contact: '+1-202-555-0194'
        }
      ],
      chart1: {
        chart: {
          height: 112,
          type: 'area',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          group: 'sparklines'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [
          {
            data: []
          }
        ],
        colors: ['#3892A6'],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime',
          range: 777600000
        },
        yaxis: {
          labels: {
            minWidth: 34
          },
          max: 100
        },
        legend: {
          show: false
        }
      },
      chart2: {
        chart: {
          height: 112,
          type: 'area',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          group: 'sparklines'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [
          {
            data: []
          }
        ],
        colors: ['#fc9f5b'],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime',
          range: 777600000
        },
        yaxis: {
          labels: {
            minWidth: 34
          },
          max: 100
        },
        legend: {
          show: false
        }
      },
      chart3: {
        chart: {
          height: 112,
          type: 'area',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          group: 'sparklines'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [
          {
            data: []
          }
        ],
        colors: ['#3892A6'],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime',
          range: 777600000
        },
        yaxis: {
          labels: {
            minWidth: 34
          },
          max: 100
        },
        legend: {
          show: false
        }
      },
      chart4: {
        chart: {
          height: 112,
          type: 'area',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          group: 'sparklines'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [
          {
            data: []
          }
        ],
        colors: ['#fc9f5b'],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime',
          range: 777600000
        },
        yaxis: {
          labels: {
            minWidth: 34
          },
          max: 100
        },
        legend: {
          show: false
        }
      }
    }
  },
  mounted () {
    xray.index()
    body[0].classList.add('sidebar-main-menu')
    this.getData()
  },
  destroyed () {
    body[0].classList.remove('sidebar-main-menu')
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get('/dashboard')
        this.dashboardData = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: error.message
        })
      }
    },
    formatDate (inputDate) {
      const date = new Date(inputDate)
      const day = date.getDate()
      const month = date.getMonth() + 1 // Los meses en JavaScript están indexados desde 0 (enero es 0)
      const year = date.getFullYear()

      // Asegurarnos de que tengamos dos dígitos para el día y el mes
      const formattedDay = day < 10 ? `0${day}` : day
      const formattedMonth = month < 10 ? `0${month}` : month

      return `${formattedDay}/${formattedMonth}/${year}`
    }
  }
}
</script>
